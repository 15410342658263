<template>
  <div>
    <v-dialog v-model='isEditCustomer' persistent max-width='800'>
      <v-card>
        <v-card-title>
          <span>แก้ไขข้อมูลลูกค้า</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formEditCustomer' @submit.prevent='updateCustomer'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12' class="d-flex">
              <v-select filled v-model='customer_prefix' :items="prefixList" dense label='ช่องทางสั่งซื้อ'
                        :rules='[required]'
                        item-value="name"
                        class="me-2"
                        style="width: 120px"
                        item-text="name"
                        placeholder='คำนำหน้า' hide-details='auto'></v-select>
              <v-text-field filled v-model='customer_fname' dense :label='`ชื่อ (${customer_prefix})`'
                            :rules='[required]'
                            class="me-2"
                            :placeholder='`ชื่อ (${customer_prefix})`'
                            hide-details='auto'></v-text-field>
              <v-text-field filled v-model='customer_lname' dense label='ชื่อผู้รับสินค้า'
                            :rules='[required]'
                            placeholder="ชื่อผู้รับสินค้า" hide-details='auto'></v-text-field>
            </v-col>

            <v-col cols='12' md='6'>
              <v-text-field
                  v-model='address.PROVINCE_NAME'
                  label='จังหวัด'
                  placeholder="จังหวัด"
                  hide-details='auto'
                  :rules='[required]'
                  dense
                  filled
              >
              </v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field
                  v-model='address.DISTRICT_NAME'
                  label='อำเภอ'
                  :rules='[required]'
                  placeholder="อำเภอ"
                  hide-details='auto'
                  dense
                  filled
              >
              </v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field
                  v-model='address.SUB_DISTRICT_NAME'
                  label='ตำบล'
                  hide-details='auto'
                  placeholder=""
                  :rules='[required]'
                  dense
                  filled
              >
              </v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field filled v-model='customer_address2' dense label='หมู่บ้านหรือถนน'
                            class='pe-2'
                            :rules='[required]'
                            placeholder='หมู่บ้านหรือถนน' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6' class='d-flex'>
              <v-text-field filled v-model='customer_address' dense label='ที่อยู่'
                            class='pe-2'
                            :rules='[required]'

                            placeholder='ที่อยู่' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field
                  v-model='address.ZIPCODE'
                  label='รหัสไปรษณีย์'
                  placeholder="รหัสไปรษณีย์"
                  hide-details='auto'
                  dense
                  filled
              >

              </v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field filled v-model='customer_phone' dense label='เบอร์โทรศัพท์'
                            class='pe-2'
                            :rules='[required]'
                            v-mask="['###-####-####']"
                            placeholder='เบอร์โทรศัพท์' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field filled v-model='customer_email' dense label='อีเมล'
                            class='pe-2'
                            placeholder='อีเมล' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field filled v-model='customer_fax' dense label='FAX '
                            class='pe-2'
                            type="number"
                            placeholder='FAX' hide-details='auto'></v-text-field>
            </v-col>

            <v-col cols='12' md='6' class='d-flex'>
              <v-text-field filled v-model='customer_ig' dense label='Instagram'
                            class='pe-2'
                            placeholder='Instagram' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6' class='d-flex'>
              <v-text-field filled v-model='customer_line' dense label='Line'
                            class='pe-2'
                            placeholder='Line' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6' class='d-flex'>
              <v-text-field filled v-model='customer_tiktok' dense label='TikTok'
                            class='pe-2'
                            placeholder='TikTok' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' class="my-1 px-1">
              <v-textarea
                  v-model='customer_note'
                  label="หมายเหตุ"
                  filled
                  placeholder="หมายเหตุ เพิ่มเติมเกี่ยวกับการส่งสินค้า"
                  dense
                  hide-details='auto'
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit'
                   :loading='loading'
                   :disabled='loading'>บันทึก
            </v-btn>
            <v-btn color='secondary' outlined @click='$emit(`update:isEditCustomer`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { api } from '@/services/api'
import { mdiCloudUploadOutline, mdiPencilOutline, mdiDeleteOutline, mdiPlus } from '@mdi/js'
import store from '@/store'
import location_new from '@/fake-db/location_new.json'
import prefix from "@/fake-db/prefix.json"

export default {
  model: {
    prop: 'isEditCustomer',
    event: 'update:isEditCustomer',
  },
  props: {
    isEditCustomer: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const customer_prefix = ref(prefix.data[0].name)
    const customer_fname = ref('')
    const customer_lname = ref('')
    const customer_email = ref('')
    const customer_phone = ref('')
    const customer_address = ref('')
    const customer_facebook = ref('')
    const customer_ig = ref('')
    const customer_address2 = ref('')
    const customer_note = ref('')
    const customer_tiktok = ref('')
    const customer_line = ref('')
    const customer_fax = ref('')
    const formEditCustomer = ref(null)
    const locationList = ref(location_new)
    const prefixList = ref(prefix.data)
    const loading = ref(false)
    const address = ref({
      SUB_DISTRICT_NAME: '',
      DISTRICT_NAME: '',
      PROVINCE_NAME: '',
      ZIPCODE: '',
    })
    const updateCustomer = () => {
      const isFormValid = formEditCustomer.value.validate()
      if (!isFormValid) return
      loading.value = true

      api.put({
        path: '/customer',
        param: props.dataEdit.customer_id,
        body: {
          customer_prefix: customer_prefix.value,
          customer_fname: customer_fname.value,
          customer_lname: customer_lname.value,
          customer_email: customer_email.value,
          customer_phone: customer_phone.value,
          customer_address: customer_address.value,
          customer_facebook: customer_facebook.value,
          customer_ig: customer_ig.value,
          customer_note: customer_note.value,
          customer_tiktok: customer_tiktok.value,
          customer_address2: customer_address2.value,
          customer_line: customer_line.value,
          customer_fax: customer_fax.value,
          customer_subdistrict: address.value.SUB_DISTRICT_NAME,
          customer_district: address.value.DISTRICT_NAME,
          customer_province: address.value.PROVINCE_NAME,
          customer_zipcode: address.value.ZIPCODE,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isEditCustomer', false)
          emit('onUpdate')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    watch(() => props.isEditCustomer, async value => {
      if (value) {
        const dataLocal = JSON.parse(JSON.stringify(props.dataEdit))
        mapData(dataLocal)
      }
    })

    const mapData = (data) => {
      customer_prefix.value = data.customer_prefix
      customer_fname.value = data.customer_fname
      customer_lname.value = data.customer_lname
      customer_email.value = data.customer_email
      customer_phone.value = data.customer_phone
      customer_address.value = data.customer_address
      customer_facebook.value = data.customer_facebook
      customer_ig.value = data.customer_ig
      customer_tiktok.value = data.customer_tiktok
      customer_line.value = data.customer_line
      customer_note.value = data.customer_note
      customer_fax.value = data.customer_fax
      customer_address2.value = data.customer_address2
      address.value = {
        SUB_DISTRICT_NAME: data.customer_subdistrict,
        DISTRICT_NAME: data.customer_district,
        PROVINCE_NAME: data.customer_province,
        ZIPCODE: data.customer_zipcode,
        SEARCH: `${ data.customer_subdistrict } > ${ data.customer_district } > ${ data.customer_province } > ${ data.customer_zipcode }`,
      }

    }

    return {
      formEditCustomer,
      updateCustomer,
      mdiPencilOutline, mdiDeleteOutline,
      mdiCloudUploadOutline,
      loading,
      mdiPlus,
      required,
      customer_prefix,
      customer_fname,
      customer_lname,
      customer_email,
      customer_phone,
      emailValidator,
      customer_address,
      customer_fax,
      customer_note,
      locationList,
      address,
      prefixList,
      customer_facebook,
      customer_ig,
      customer_tiktok,
      customer_address2,
      customer_line,
    }
  },

}
</script>

<style scoped>
.set-block {
  max-width: 300px;
  max-height: 200px;
  border: 1px dashed #212121;
  border-radius: 10px;
  background-color: #d6d6d6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
