<template>
  <div>
    <v-dialog v-model='isShowDetail' persistent max-width='500'>
      <v-card>
        <v-card-title>
          รายละเอียดลูกค้า
        </v-card-title>
        <v-divider></v-divider>
        <v-row class='mx-2 my-2'>
          <v-col cols='6' class='font-weight-semibold text-right'>
            รหัสลูกค้า :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_code }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            ช่องทาง :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_prefix }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            ชื่อลูกค้า :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_fname }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            ชื่อผู้รับสินค้า :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_lname }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            ที่อยู่ :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_full_address }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            เบอร์โทรศัพท์ :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_phone }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            อีเมล :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_email }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            FAX :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_fax_number }}
          </v-col>

          <v-col cols='6' class='font-weight-semibold text-right'>
            Facebook :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_facebook }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            Instagram :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_ig }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            line :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_line }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            TikTok :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.customer_tiktok }}
          </v-col>


        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='secondary' outlined @click='$emit(`update:isShowDetail`,false )'>ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'isShowDetail',
    event: 'update:isShowDetail',
  },
  props: {
    isShowDetail: {
      type: Boolean,
      default: false,
    },
    dataDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(_, { root, emit }) {

    return {}
  },

}
</script>

<style scoped>

</style>
