import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import { api } from '@/services/api'

export default function useCustomer() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'customer_id',
            sortable: false,
            width: '50',
        },
        {
            text: 'รหัสลูกค้า',
            value: 'customer_code',
            width: '120',
        },
        {
            text: 'ช่องทาง',
            value: 'customer_prefix',
            width: '120',
        },
        {
            text: 'ชื่อ social',
            value: 'customer_fname',
            width: '200',
        },
        {
            text: 'ชื่อผู้รับ',
            value: 'customer_lname',
            width: '200',
        },
        {
            text: 'ที่อยู่',
            value: 'customer_full_address',
            width: '200',
        },
        {
            text: 'เบอร์โทรศัพท์',
            value: 'customer_phone',
            width: '140',
        },
        {
            text: 'อีเมล',
            value: 'customer_email',
            width: '120',
        },
        {
            text: 'สถานะ',
            value: 'customer_active',
            width: '100',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '200',
            align: 'center fixed',
        },
    ])
    const itemsPerPage = ref(10)
    const status = ref('1')
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const isShowDetail = ref(false)
    const changeStatus = (id) => {
        status.value = id
        getCustomers()
    }

    const getCustomers = () => {
        loading.value = true
        api.get({
            path: '/customers',
            param: `?search=${ textSearch.value }&active=${ status.value }`,
        }).then(({ data }) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getCustomers()
    })

    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        changeStatus,
        getCustomers,
    }
}
